<template>
  <div>
    <!-- <el-carousel class="head">
      <el-carousel-item v-for="item in banner" :key="item.id">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel> -->

    <!-- <div class="head">
      <img :src="hireInfo.banner" />
    </div> -->

    <div class="head">
      <div class="head-left">
        <img src="../../assets/hire/start.png" alt="" />
        <h2>薪跳加速 职等你来</h2>
      </div>

      <div class="head-right">
        <img src="../../assets/hire/job.png" alt="" />
        <!-- <img src="../../assets/hire/Rectangle.png" alt="" /> -->
      </div>
    </div>

    <!-- <div class="content w">
      <div class="desc">
        <h2>如果你是</h2>
        <div class="section" v-for="job in jobLists" :key="job.id">
          <div class="job-user">
            <img :src="avatar" alt="" />
            <div>
              <p>{{ job.positionName }}</p>
              <p>工程师</p>
            </div>
          </div>
          <div class="job-desc">
            <div class="duty">
              <h3>岗位职责</h3>
              <p style="white-space: pre-wrap" v-html="job.positionDuty"></p>
            </div>
            <div class="request">
              <h3>职位要求</h3>
              <p
                style="white-space: pre-wrap"
                v-html="job.positionRequirement"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="welcome">
      <h3
        style="
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 48px;
          color: #333333;
          margin-bottom: 80px;
        "
      >
        期待你的加入
      </h3>
      <div class="company w">
        <div class="item">
          <h3>企业文化</h3>
          <p style="scrollbar-width: none !important">
            {{ hireInfo.enterpriseCulture }}
          </p>
        </div>
        <div class="divid"></div>
        <div class="item">
          <h3>基本福利和保障</h3>
          <p style="scrollbar-width: none !important">
            {{ hireInfo.benefitsGuarantee }}
          </p>
        </div>
        <div class="divid"></div>
        <div class="item third">
          <h3>工作时间和地点</h3>
          <p style="scrollbar-width: none !important">
            <i>{{ hireInfo.workTime }}</i>
            <i>{{ hireInfo.workPlace }}</i>
          </p>
        </div>
      </div>
    </div> -->

    <info-page />

    <div class="locate">
      <div class="office">
        <div
          class="bg w"
          :style="{ 'background-image': 'url(' + rightPic + ')' }"
        ></div>
        <div class="email">
          <p>期待你的加入</p>
          <div class="dot"></div>
          <p>发送简历</p>
          <button
            class="text"
            :data-clipboard-text="hireInfo.email"
            @click="copy"
          >
            {{ hireInfo.email }}
          </button>
        </div>
      </div>
      <div id="container"></div>
    </div>
  </div>
</template>

<script>
import { getBnners } from '../../api/banner.js'
import { hireInfo } from '../../api/hire.js'
import { webKey } from '../../config/config.js'
import AMapLoader from '@amap/amap-jsapi-loader'
import infoPage from '../../components/infoPage.vue'
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      map: null,
      avatar: require('../../assets/imgs/contact.png'),
      rightPic: require('../../assets/imgs/about/office.png'),
      hireInfo: {},
      jobLists: [],
      banner: [],
      isClick: false
    }
  },
  components: { infoPage },
  created() {
    this.initData()
  },
  methods: {
    initMap(p) {
      AMapLoader.load({
        key: webKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 18, //初始化地图级别
            center: [p.longitude, p.latitude] //初始化地图中心点位置
            // center: [104.068329, 30.534344],
          })

          var markerPoint = new AMap.Marker({
            // position: [104.068329, 30.534344],
            position: [p.longitude, p.latitude] // 经纬度
            // offset: new AMap.Pixel(-12, -32), // 标记点偏移量
            // icon: this.locateUrl, // 添加 Icon 图标 URL（不加icon应该有默认标记）
          })
          this.map.add(markerPoint)
        })
        .catch((e) => {
          console.log(e)
        })
    },

    initData() {
      hireInfo()
        .then((res) => {
          if (res.status === 200) {
            this.jobLists = res.data.recruitmentPostList
              ? res.data.recruitmentPostList
              : []
            this.hireInfo = res.data ? res.data : {}
            let longitude = this.hireInfo.longitude
            let latitude = this.hireInfo.latitude
            this.initMap({ longitude, latitude })
          }
        })
        .catch((error) => {
          console.log('error', error)
        })

      getBnners({ classifyId: -3 }).then((res) => {
        this.banner = res.data
      })
    },
    copy() {
      var clipboard = new Clipboard('.text')
      clipboard.on('success', (e) => {
        this.$message({
          message: '邮箱复制成功',
          type: 'success'
        })

        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持复制',
          type: 'error'
        })
        // 释放内存
        clipboard.destroy()
      })
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
// .btn-2 {
//   background: rgb(96, 9, 240);
//   background: linear-gradient(
//     0deg,
//     rgba(96, 9, 240, 1) 0%,
//     rgba(129, 5, 240, 1) 100%
//   );
//   border: none;
// }
// .btn-2:before {
//   height: 0%;
//   width: 2px;
// }
.btn {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
}

/deep/.el-carousel__container {
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
/deep/.amap-marker {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  background: rgba(110, 190, 249, 0.3) !important;
  img {
    width: 35px;
    height: 50px;
  }
}
// .head {
//   width: 100%;
//   height: 400px;
//   margin-bottom: 30px;
//   img {
//     width: 100%;
//     height: 100%;
//   }
//   // height: 400px;
//   // background-image: url("../../assets/imgs/about/about.png");
//   // margin-bottom: 100px;
//   // font-family: "Microsoft YaHei";
//   // font-style: normal;
//   // font-weight: 400;
//   // color: #ffffff;
//   // display: flex;
//   // flex-direction: column;
//   // align-items: center;
//   // justify-content: center;
//   // h2 {
//   //   font-size: 40px;
//   //   line-height: 48px;
//   //   margin-bottom: 40px;
//   // }
//   // p {
//   //   font-size: 28px;
//   //   line-height: 48px;
//   // }
// }

.head {
  width: 100%;
  height: 650px;
  background: #2473e9;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 80px;
  box-sizing: border-box;
  .head-left {
    display: flex;

    img {
      width: 38px;
      height: 46px;
      margin-right: 20px;
    }
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 48px;
      color: #ffffff;
    }
  }
  .head-right {
    width: 617px;
    height: 361px;
    // background: #1c5ab8;
    border-radius: 20px;
    position: relative;
    img {
      position: absolute;
      top: -30px;
      left: -20px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.content {
  padding-top: 10px;
  box-sizing: border-box;
  .desc {
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      color: #333333;
    }
    .section {
      background: #ffffff;
      padding: 40px 109px 40px 100px;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.5);
      border-radius: 24px;
      margin: 80px 0;
      display: flex;
      align-items: center;
      overflow: hidden;
      .job-user {
        display: flex;
        align-items: center;
        width: 40%;
        margin-right: 60px;
        overflow: hidden;
        img {
          width: 104px;
          height: 104px;
          border-radius: 52px;
          margin-right: 48px;
        }
        div {
          text-align: left;
          font-style: normal;
          font-weight: 400;
          flex: 1;
          p:nth-child(1) {
            width: 100%;
            height: 37px;
            overflow: hidden;
            font-size: 28px;
            font-weight: 700;
            line-height: 37px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 16px;
          }
          p:nth-child(2) {
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.5);
            width: 100px;
            height: 24px;
            overflow: hidden;
          }
        }
      }
      .job-desc {
        width: 50%;
        text-align: left;
        div {
          font-style: normal;
          font-weight: 400;
          h3 {
            font-size: 26px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 16px;
          }
          p {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 2px;
            color: rgba(0, 0, 0, 0.4);
          }
        }
        .duty {
          margin-bottom: 40px;
        }
      }
    }
  }
}

// .locate {
//   height: 768px;
//   .map {
//     position: relative;
//     height: 480px;
//     background-image: url("../../assets/imgs/about/locate.png");
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     .company {
//       position: absolute;
//       left: 50%;
//       top: -130px;
//       transform: translateX(-50%);
//       height: 200px;
//       background: #ffffff;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       padding: 0 50px;
//       box-sizing: border-box;
//       box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.5);
//       border-radius: 24px;
//       text-align: left;
//       .item {
//         flex: 1;
// =======
.welcome {
  width: 100%;
  height: 260px;
  position: relative;
  .company {
    z-index: 3;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    // height: 200px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    text-align: left;
    .item {
      flex: 1;
      overflow: hidden;
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #000000;
        line-height: 41px;
        margin-bottom: 17px;
        overflow: hidden;
        height: 41px;
      }
      p {
        cursor: pointer;
        height: 100px;
        overflow: scroll;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 24px;
        color: #666666;
        letter-spacing: 1px;
        display: flex;
        flex-direction: column;
        i {
          font-style: normal;
        }
      }
    }
    .divid {
      width: 1px;
      height: 64px;
      background: #eeeeee;
      margin: 0 52px 0 40px;
    }
  }
}
.locate {
  height: 1068px;
  #container {
    position: relative;
    width: 100%;
    height: 680px;
  }
  .office {
    position: relative;
    height: 440px;
    overflow: hidden;
    background: linear-gradient(90deg, #3a95ff 0%, #84d8f2 100%);
    .bg {
      height: 100%;
    }
    .email {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      text-align: center;
      p {
        text-align: center;
        width: 800px;
        height: 37px;
        font-style: normal;
        letter-spacing: 2px;
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
        color: #ffffff;
        margin-bottom: 34px;
      }
      button {
        border: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 56px;
        text-align: center;
        color: #00bbff;
        padding: 0 60px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 100px;
      }
    }
    .dot {
      margin: 0 auto;
      width: 38px;
      height: 6px;
      background: #ffffff;
      border-radius: 10px;
    }
  }
}
</style>
