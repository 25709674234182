<template>
  <div class="box">
    <div class="desc w">
      <div class="desc-head">
        <img src="../assets/hire/teaml.png" alt="" />
        <h1>团队介绍</h1>
        <img src="../assets/hire/teamr.png" alt="" />
      </div>
      <div class="desc-content">
        <div class="desc-item" data-aos="fade-up">
          <div class="topImgBox">
            <div class="logo">
              <img src="../assets/hire/rt.png" alt="" />
              <img src="../assets/hire/lb.png" alt="" />
            </div>
          </div>
          <div class="descr">企业文化</div>
          <!-- <p style="white-space: pre-wrap;text-align:left;" v-html="basicInfo.enterpriseCulture">
          </p> -->
          <p>
            {{ basicInfo.enterpriseCulture }}
          </p>
        </div>
        <div class="desc-item" data-aos="fade-down">
          <div class="topImgBox">
            <div class="logo">
              <img src="../assets/hire/zfxr.png" alt="" />
              <img src="../assets/hire/zfxl.png" alt="" />
            </div>
          </div>

          <div class="descr">基本福利和保障</div>
          <p>{{ basicInfo.benefitsGuarantee }}</p>
        </div>
        <div class="desc-item" data-aos="fade-up">
          <div class="topImgBox">
            <div class="logo">
              <img src="../assets/hire/sjxr.png" alt="" />
              <img src="../assets/hire/sjxl.png" alt="" />
            </div>
          </div>

          <div class="descr">工作时间和地点</div>
          <p>
            <i style="font-style: normal">{{ basicInfo.workTime }}</i>
            <br />
            <i style="font-style: normal">{{ basicInfo.workPlace }}</i>
          </p>
        </div>
      </div>
    </div>
    <div class="content w">
      <div class="item" data-aos="fade-right">
        <div class="left">
          <div class="content-head">
            <img src="../assets/hire/teaml.png" alt="" />
            <h1>我们的工作氛围</h1>
          </div>
          <div class="sections">
            <div class="section">
              <h4>充满人文关怀和幸福感</h4>
              <p>
                同事之间相处融洽，互帮互助。工作上，每个部门进行定期交流培训，实行老对新帮扶式学习，让新员工可以尽快地融入公司氛围中来；生活上，定期举办聚餐以及户外运动，每年至少一次公费旅游
              </p>
            </div>
            <div class="section">
              <h4>晒晒我的工作环境</h4>
              <p>
                公司坐落在美年广场，地铁站天府五街步行200即到，楼下有咖啡厅、餐饮、健身房、桌游、零售店。
              </p>
            </div>
          </div>
        </div>
        <div class="right">
          <div>
            <el-carousel
              ref="carousel"
              :loop="true"
              :interval="5000"
              arrow="never"
              indicator-position="none"
              height="418px"
            >
              <el-carousel-item v-for="item in swiperListsOne" :key="item.id">
                <img :src="item.url" alt="" />
              </el-carousel-item>
            </el-carousel>
            <!-- <img src="../assets/hire/life.png" alt="" /> -->
          </div>
        </div>
      </div>
      <div class="item" data-aos="fade-left">
        <div class="left">
          <div class="content-head">
            <h1>为什么选择我们</h1>
            <img src="../assets/hire/teaml.png" alt="" />
          </div>
          <div class="sections">
            <div class="section">
              <h4>丰富的项目经验</h4>
              <p>
                公司一直致力于计算机应用软件开发领域，积累了丰富的项目开发、实施、产品设计经验。目前拥有一支强大的软件开发、测试队伍，依靠精深的计算机经验、及其丰富的项目实施经验和严格的软件管理过程，在个开发领域已树立起质量、服务一流的形象
              </p>
            </div>
            <div class="section">
              <h4>专业的团队</h4>
              <p>
                公司已有员工70余人，荟萃了一批在IT业界奋斗多年的精英力量，总部位于成都，在重庆开设分公司，拥有很多富有软件开发经验的专业技术人才
              </p>
            </div>
          </div>
        </div>
        <div class="right">
          <div>
            <el-carousel
              ref="carousel"
              :loop="true"
              :interval="7000"
              arrow="never"
              indicator-position="none"
              height="418px"
            >
              <el-carousel-item v-for="item in swiperListsTwo" :key="item.id">
                <img :src="item.url" alt="" />
              </el-carousel-item>
            </el-carousel>
            <!-- <img src="../assets/hire/life.png" alt="" /> -->
          </div>
        </div>
      </div>
      <!-- <div class="item" data-aos="fade-left">
        <div class="left">
          <div class="content-head">
            <h1>为什么选择我们</h1>
            <img src="../assets/hire/teaml.png" alt="" />
          </div>
          <div class="sections">
            <div class="section">
              <h4>务实高效的工作方式</h4>
              <p>
                关注本质，不形式主义，重视用户反馈和实际效果
                提倡创新创造，像创业第一天那样思考，保持活力
                坚持高标准，鼓励为更好的结果做突破性尝试
              </p>
            </div>
            <div class="section">
              <h4>务实高效的工作方式</h4>
              <p>
                关注本质，不形式主义，重视用户反馈和实际效果
                提倡创新创造，像创业第一天那样思考，保持活力
                坚持高标准，鼓励为更好的结果做突破性尝试
              </p>
            </div>
            <div class="section">
              <h4>务实高效的工作方式</h4>
              <p>
                关注本质，不形式主义，重视用户反馈和实际效果
                提倡创新创造，像创业第一天那样思考，保持活力
                坚持高标准，鼓励为更好的结果做突破性尝试
              </p>
            </div>
          </div>
        </div>
        <div class="right">
          <div>
            <img src="../assets/hire/life.png" alt="" />
          </div>
        </div>
      </div> -->
    </div>
    <div class="company" data-aos="fade-up">
      <img src="../assets/hire/company.png" alt="" />
      <h2>来自众多企业的信赖</h2>
      <button @click="$router.push('/customService')">了解客户案例</button>
    </div>
    <div style="width: 100%; background: #f9f9f9">
      <div class="job w">
        <div class="job-head">
          <img src="../assets/hire/teaml.png" alt="" />
          <h1>我们提供的岗位</h1>
          <img src="../assets/hire/teamr.png" alt="" />
        </div>
        <div class="colleagues">
          <div
            class="colleague"
            @mouseenter="mouseEnter(index)"
            :class="currentIndex == index ? 'active' : ''"
            v-for="(item, index) in jobLists"
            :key="item.id"
          >
            <div class="top">
              <div>
                <h3>{{ item.positionName }}</h3>
                <div class="dot"></div>
              </div>
              <p class="slary">{{ item.basicSalary }}</p>
              <p class="experienc">{{ item.workExperience }}</p>
            </div>
            <div class="bottom">
              <div class="divid"></div>
              <p @click="handleClick(item.id)">
                详情
                <i class="iconfont icon-chakangengduo"></i>
              </p>
            </div>
            <!-- <div
            class="back"
            ref="back"
            v-show="currentIndex === index && isShowisShowMask"
          >
            <div class="back-head" @click="handleClick(index, 'toFront')">
              <i class="iconfont icon-fanhui"></i>
              <h2>岗位要求</h2>
            </div>
            <div class="back-box">
              <p style="white-space: pre-wrap" v-html="item.positionDuty"></p>
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hireInfo } from '../api/hire.js'
export default {
  name: 'Box',
  components: {},
  data() {
    return {
      jobLists: [],
      currentIndex: 0,
      isShowisShowMask: false,
      currentSwiperIndex: 0,
      swiperListsOne: [
        { id: 1, url: require('../assets/hire/workone.jpg') },
        { id: 2, url: require('../assets/hire/worktwo.jpg') },
        { id: 3, url: require('../assets/hire/workthree.jpg') },
        { id: 4, url: require('../assets/hire/workone.jpg') },
        { id: 5, url: require('../assets/hire/worktwo.jpg') },
        { id: 6, url: require('../assets/hire/workthree.jpg') }
      ],
      swiperListsTwo: [
        { id: 1, url: require('../assets/hire/choiceone.jpg') },
        { id: 2, url: require('../assets/hire/choicetwo.jpg') },
        { id: 3, url: require('../assets/hire/choiceone.jpg') },
        { id: 4, url: require('../assets/hire/choicetwo.jpg') }
      ],
      basicInfo: {
        enterpriseCulture: '',
        benefitsGuarantee: '',
        workPlace: '',
        workTime: ''
      }
    }
  },
  created() {
    this.initData()
  },
  methods: {
    mouseEnter(index) {
      this.currentIndex = index
    },
    initData() {
      hireInfo()
        .then((res) => {
          if (res.status === 200) {
            console.log('人才招聘信息', res.data)
            this.jobLists = res.data.recruitmentPostList
              ? res.data.recruitmentPostList
              : []

            this.basicInfo = {
              enterpriseCulture: res.data.enterpriseCulture,
              benefitsGuarantee: res.data.benefitsGuarantee,
              workPlace: res.data.workPlace,
              workTime: res.data.workTime
            }
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    handleClick(id) {
      // this.currentIndex = index;
      // switch (type) {
      //   case "toBack":
      //     this.isShowisShowMask = true;
      //     break;
      //   case "toFront":
      //     this.isShowisShowMask = false;
      //     break;
      //   default:
      //     break;
      // }
      this.$router.push(`/detail/${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.w {
  width: 1200px;
  margin: 0 auto;
}
.box {
  .desc {
    padding: 100px 0 200px;
    .desc-head {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
      img {
        width: 36px;
        height: 36px;
      }
      h1 {
        font-family: "'OPPOSans-M'";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 48px;
        color: #333333;
        margin: 0 20px;
      }
    }
    .desc-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .desc-item {
        width: 374px;
        height: 374px;
        padding: 20px 40px 30px;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(12px);
        border: 1px solid rgba(143, 134, 134, 0.141);
        box-shadow: rgba(142, 142, 142, 0.19) 6px 6px 15px 0px,
          rgba(142, 142, 142, 0.19) -6px -6px 15px 0px;
        -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
        border-radius: 17px;
        -webkit-border-radius: 17px;
        color: rgba(255, 255, 255, 0.3);
        display: flex;
        flex-wrap: wrap;
        /* flex-direction: column;
        align-items: center;
        justify-content: space-around; */
        // margin-bottom: 30px;
        .logo {
          width: 100px;
          height: 70px;
          position: relative;
          img {
            width: 90px;
            height: 66px;
          }
          img:nth-child(1) {
            position: absolute;
            top: 15px;
            right: -15px;
            z-index: 1;
          }
          img:nth-child(2) {
            position: absolute;
            bottom: -20px;
            left: -15px;
          }
        }

        .descr {
          // font-style: normal;
          // font-weight: 700;
          width: 100%;
          text-align: center;
          display: block;
          font-family: 'OPPOSans-M';
          font-size: 24px;
          line-height: 48px;
          color: #2473e9;
          margin: 0;
          height: 50px !important;
          // padding-bottom: 16px;
        }
        p {
          height: 100px;
          margin: 0;
          overflow: hidden;
          font-family: 'OPPOSans-M';
          font-size: 14px;
          line-height: 26px;
          text-align: center;
          color: #333333;
        }
      }
    }
  }
  .content {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 80px;
      &:nth-child(even) {
        .left {
          order: 1;
          padding-left: 200px;
          box-sizing: border-box;
          text-align: right;
          .content-head {
            justify-content: flex-end !important;
            img {
              margin-left: 30px;
            }
          }
        }
      }
      &:nth-child(odd) {
        .left {
          order: 0;
          padding-right: 200px;
          box-sizing: border-box;
          text-align: left;
          .content-head {
            justify-content: flex-start !important;
            img {
              margin-right: 30px;
            }
          }
        }
      }
      .left {
        flex: 1;
        .content-head {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 80px;
          img {
            width: 30px;
            height: 30px;
          }
          h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 48px;
            color: #333333;
          }
        }
        .sections {
          .section {
            margin-bottom: 50px;
            h4 {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
              color: #000;
              margin-bottom: 20px;
            }
            p {
              // font-style: normal;
              // font-weight: 700;
              font-family: 'OPPOSans-M';
              font-size: 16px;
              line-height: 30px;
              // letter-spacing: 2px;
              color: #666;
            }
          }
        }
      }
      .right {
        width: 418px;
        height: 418px;
        overflow: hidden;
        position: relative;
        > div {
          width: 418px;
          height: 418px;
          overflow: hidden;
          position: absolute;
          z-index: 0;
          animation: animate 7s -3s linear infinite reverse;
          border-radius: 55% 45% 52% 48% e('/') 37% 43% 57% 63%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s linear;
          }
        }
      }
      // 不规则圆 参考地址: `https://9elements.github.io/fancy-border-radius/#39.46.38.54--.`
      @keyframes animate {
        0% {
          border-radius: 55% 45% 52% 48% e('/') 37% 43% 57% 63%;
        }
        25% {
          border-radius: 65% 35% 62% 38% e('/') 47% 61% 39% 53%;
        }
        50% {
          border-radius: 74% 26% 37% 63% e('/') 55% 45% 55% 45%;
        }
        75% {
          border-radius: 61% 39% 68% 32% e('/') 30% 57% 43% 70%;
        }
        100% {
          border-radius: 55% 45% 52% 48% e('/') 37% 43% 57% 63%;
        }
      }
    }
  }
  .company {
    width: 100%;
    height: 640px;
    background: #edf4ff;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
    }
    h2 {
      position: absolute;
      top: 40%;
      left: 50%;
      font-family: 'OPPOSans-M';
      transform: translate(-50%, -50%);
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.15em;
      color: #333333;
      z-index: 1;
    }
    button {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 225px;
      height: 60px;
      background: linear-gradient(
        180deg,
        rgba(0, 117, 255, 0.79) 0%,
        #186dee 100%
      );
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      border: 0;
      font-family: 'OPPOSans-M';
      color: #fff;
      font-size: 16px;
      // font-weight: 400;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s linear;
      &:hover {
        font-weight: 700;
      }
    }
  }
  .job {
    padding: 100px 0;
    box-sizing: border-box;
    .job-head {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 100px;
      img {
        width: 36px;
        height: 36px;
      }
      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 48px;
        text-align: center;
        color: #333333;
        margin: 0 30px;
      }
    }
    .colleagues {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 100px;
      .colleague {
        width: 350px;
        height: 350px;
        border-radius: 16px;
        padding: 50px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        text-align: center;
        margin-bottom: 20px;
        transition: all 0.3s linear;
        cursor: pointer;
        margin: 0px 10px 20px 0;
        position: relative;
        transition: all 0.3s linear;
        // background-color: #eee;
        background: #fff;
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
        -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        color: #000;
        opacity: 0.7;
        &.active {
          background-color: #467dec;
          opacity: 1;
          .top {
            > div {
              h3 {
                color: #fff;
                font-size: 24px;
                font-family: 'OPPOSans-M';
              }
            }
            .slary {
              color: rgba(255, 255, 255, 0.85);
            }
            .experienc {
              color: rgba(255, 255, 255, 0.85);
            }
          }
        }
        .back {
          border-radius: 16px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 500px;
          overflow: scroll;
          z-index: 2;
          background-color: #0093e9;
          background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
          transition: all 0.3s linear;
          overflow: hidden;
          opacity: 1;
          .back-head {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            transition: all 0.3s linear;
            i {
              font-size: 28px;
              font-weight: 700;
              margin-right: 20px;
            }
            &:hover {
              color: #fff;
              transform: scale(1.1);
            }
          }
          .back-box {
            height: 420px;
            overflow: scroll;
            padding: 20px;
            box-sizing: border-box;
            p {
              height: 100%;
              margin: 0;
              overflow: scroll;
              text-align: left;
              font-size: 20px;
              font-weight: 700;
              line-height: 34px;
              color: #333;
              letter-spacing: 2px;
              transition: all 0.3s cubic-bezier(0.17, 0.67, 0.92, 0.06);
            }
            //  &:hover p{
            //   color: #fff;
            //  }
          }
        }

        .top {
          > div {
            position: relative;
            margin-bottom: 20px;
            h3 {
              color: #2473e9;
              // font-style: normal;
              // font-weight: 700;
              font-family: 'OPPOSans-M';
              font-size: 24px;
              line-height: 48px;
            }
            .dot {
              width: 142px;
              height: 10px;
              background: rgba(255, 255, 255, 0.3);
              border-radius: 10px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          p {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            font-family: 'OPPOSans';
            text-align: center;
            color: #333333;
          }
        }
        .bottom {
          margin-top: 40px;
          p {
            font-style: normal;
            font-weight: 700;
            font-family: 'OPPOSans';
            font-size: 16px;
            line-height: 32px;
            text-align: center;
            margin-top: 30px;
            transition: all.3s linear;
            color: #fff;
            i {
              font-size: 20px;
              margin-left: 10px;
              vertical-align: middle;
            }

            &:hover {
              transform: scale(1.1);
            }
          }
          .divid {
            width: 100px;
            height: 4px;
            border-radius: 4px;
            background: #fff;
            opacity: 0.65;
          }
        }
      }
    }
  }
}

.topImgBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-carousel__item {
  transition: all 0.5s linear;
}
</style>
