import { get, post } from './index'

// 人才招聘信息
export const hireInfo = (p) => {
  return get('/api/talentRecruitment/getTalentRecruitmentPageMessage', p)
}

// 岗位详情
export const hireDetail = (p) => {
  return get('/api/talentRecruitment/getRecruitmentPostDetails', p)
}
